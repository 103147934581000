import { useEffect, useState } from 'react';
import Photo from './Photo';
import axios from 'axios';
import './App.css';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';

import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';


const baseapiurl =  "https://uq8wo6ap4l.execute-api.eu-central-1.amazonaws.com";
const guestsTable =  "https://uq8wo6ap4l.execute-api.eu-central-1.amazonaws.com/items";
const guestimgprefix =  "https://btempbuck.s3.eu-central-1.amazonaws.com/";


Array.prototype.max = function() {
  return Math.max.apply(null, this);
};

Array.prototype.min = function() {
  return Math.min.apply(null, this);
};

Array.prototype.round = function() {
  return Math.round.apply(null, this);
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
   transform: 'translate(-50%, -50%)',
  width: 1500,
  height: 800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
  backgroundColor: "black",
  
};

export default function App() {

  function createData(dropid, starttime, endtime, uploadedimagescount, sms, mail, listoffiles ) {
    return { dropid, starttime, endtime, uploadedimagescount, sms, mail, listoffiles };
  }
  function createGuestData(guest_id, guest_image, mail, phone, images, galentry, lastentrysec) {
    return { guest_id, guest_image, mail, phone, images, galentry, lastentrysec };
  }

  function compare( a, b ) {
    if ( a.starttime > b.starttime ){
      return -1;
    }
    if ( a.starttime < b.starttime ){
      return 1;
    }
    return 0;
  }

  

  function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),        
        hours = '' + d.getHours(),
        minute = '' + d.getMinutes();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
    if (hours.length < 2) 
        hours = '0' + hours;
    if (minute.length < 2) 
        minute = '0' + minute;

    return [day, month].join('-') + "   " + [hours, minute].join(':');
}

  
  const [guests, setGuests] = useState([]);
  const [events, setEvents] = useState([]);
  
  const [sms, setSMS] = useState([]);
  const [mails, setMails] = useState([]);
  const [entries, setEntries] = useState([]);
  const [rows, setRows] = useState([]);
  const [guestrows, setGuestRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [clientfiles, setclientfiles] = useState([]);
  const handleOpen = (files) => {
    setclientfiles(files);
    setOpen(true)
  };
  const handleClose = () => setOpen(false);

  useEffect(() => {
    const fetch = async() => {
      
      
      const { data } = await axios.get(`${guestsTable}`);
      
      setGuests(data.Items);

      var ntf = await axios.get(`${baseapiurl}/notif`);
      
      setSMS(ntf.data.Items.filter(s => s.type === "SMS"));
      setMails(ntf.data.Items.filter(m => m.type === "MAIL"));

      var ent =  await axios.get(`${baseapiurl}/galleryentries`);
      setEntries(ent.data.Items);
      
      
      var evts = await axios.get(`${baseapiurl}/events`);
      setEvents(evts.data.Items);      
      setRows(evts.data.Items.map(e => createData(
        e.dropid, 
        Date.parse(e.starttime) + 3*60*60*1000, 
        Date.parse(e.endtime) + 3*60*60*1000, 
        e.uploadedimagescount,
        ntf.data.Items.filter(s => (s.type === "SMS" && s.dropid === e.dropid)).length,
        ntf.data.Items.filter(s => (s.type === "MAIL" && s.dropid === e.dropid)).length,              
        e.processedimagesatclient ? JSON.parse(e.processedimagesatclient) : [])));

      //guest_id, guest_image, mail, phone, images
      var guestentriestable = data.Items.map(g => {
        var guestentries = ent.data.Items.filter(gl => gl.guestid === g.guest_id);
        var lstentriesall = guestentries.map(ge => (Date.now() - Date.parse(ge.entrytime)));
        var lstarr = lstentriesall.map(l => (l / 1000))  
        var lst = lstarr.min(); 

        return {
          guest_id: g.guest_id,
          guest_image: g.guest_image,
          mail: g.mail,
          phone: g.phone,
          images: g.images,
          guestentriesno: guestentries,
          lst_o: lst
        };
      });
      
            
      var table = guestentriestable.sort((a,b) =>  a.lst_o < b.lst_o ? -1 : 1).map(g => {
          return createGuestData(
            g.guest_id,
            g.guest_image,
            g.mail,
            g.phone,
            g.images,
            g.guestentriesno,
            g.lst_o
          );
      });

      setGuestRows(table);            

    }
    fetch();
  }, [])
  
  return (
    <div className="main">
      <div className="container">
        <div className="content" >
          <Card sx={{ minWidth: 180 }}  style={{backgroundColor: "black", color: "white"}}>
          <CardContent>        
            <Typography variant="h5" component="div"> Guests </Typography>
            <Typography sx={{ mb: 1.5 }} > {guests.length} </Typography>        
          </CardContent>
          {/* <CardActions>
            <Button size="small">Learn More</Button>
          </CardActions> */}
          </Card>
        
          <Card sx={{ minWidth: 180 }} style={{backgroundColor: "black", color: "white"}}>
            <CardContent>        
              <Typography variant="h5" component="div"> Images </Typography>
              <Typography sx={{ mb: 1.5 }} > {events.length > 0 ? events.map(x => x.uploadedimagescount).reduce((a, b) => parseInt(a) + parseInt(b)) : 0} </Typography>        
            </CardContent>
            {/* <CardActions>
              <Button size="small">Learn More</Button>
            </CardActions> */}
          </Card>
        
          <Card sx={{ minWidth: 180 }} style={{backgroundColor: "black", color: "white"}}>
            <CardContent>        
              <Typography variant="h5" component="div"> Drops </Typography>
              <Typography sx={{ mb: 1.5 }} > {events.length} </Typography>        
            </CardContent>
            {/* <CardActions>
              <Button size="small">Learn More</Button>
            </CardActions> */}
          </Card>

          <Card sx={{ minWidth: 180 }} style={{backgroundColor: "black", color: "white"}}>
            <CardContent>        
              <Typography variant="h5" component="div"> Notifications </Typography>
              <Typography sx={{ mb: 1.5 }} >SMS: {sms.length} MAILS: {mails.length} </Typography>                  
            </CardContent>
            {/* <CardActions>
              <Button size="small">Learn More</Button>
            </CardActions> */}
          </Card>
            
          <Card sx={{ minWidth: 180 }} style={{backgroundColor: "black", color: "white"}}>
            <CardContent>        
              <Typography variant="h5" component="div"> Gallery Entries </Typography>
              <Typography sx={{ mb: 1.5 }} > {entries.filter(e => e.type == "gallery").length} </Typography>        
            </CardContent>
            {/* <CardActions>
              <Button size="small">Learn More</Button>
            </CardActions> */}
          </Card>        

          <Card sx={{ minWidth: 180 }} style={{backgroundColor: "black", color: "white"}}>
            <CardContent>        
              <Typography variant="h5" component="div"> Shares </Typography>
              <Typography sx={{ mb: 1.5 }} > {entries.filter(e => e.type == "imageshare").length} </Typography>        
            </CardContent>
            {/* <CardActions>
              <Button size="small">Learn More</Button>
            </CardActions> */}
          </Card>    
      </div>
      </div>
      <div className="container">
        <TableContainer component={Paper}>
          <Table sx={{ 
                      minWidth: 650,
                      backgroundColor: "black", 
                      "& th": { fontSize: "1.0rem", color: "rgba(255, 255, 255)" },
                      "& td": { fontSize: "1.0rem", color: "rgba(255, 255, 255)" }}} 
                      aria-label="simple table" >
            <TableHead >
              <TableRow>
                <TableCell align="center">Drop ID</TableCell>
                <TableCell align="center">Start Time</TableCell>
                <TableCell align="center">End Time</TableCell>
                <TableCell align="center">Uploaded Photos</TableCell>
                <TableCell align="center"># SMS</TableCell>
                <TableCell align="center"># Mails</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.sort(compare).map((row) => (
                <TableRow 
                    key={row.dropid} 
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                  <TableCell align="center" component="th" scope="row"> {row.dropid}</TableCell>
                  <TableCell align="center">{formatDate(row.starttime)}</TableCell>
                  <TableCell align="center">{formatDate(row.endtime)}</TableCell>
                  <TableCell align="center">{row.uploadedimagescount}</TableCell>
                  <TableCell align="center">{row.sms}</TableCell>
                  <TableCell align="center">{row.mail}</TableCell>
                  <TableCell align="center"><Button onClick={() => handleOpen(row.listoffiles)}>Client Files</Button></TableCell>
                  
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className="container">
        <TableContainer component={Paper}>
          <Table sx={{ 
                      minWidth: 650,
                      backgroundColor: "black", 
                      "& th": { fontSize: "1.0rem", color: "rgba(255, 255, 255)" },
                      "& td": { fontSize: "1.0rem", color: "rgba(255, 255, 255)" }}} 
                      aria-label="simple table" >
            <TableHead >
              <TableRow>
                <TableCell align="center">Guest ID</TableCell>
                <TableCell align="center">Image</TableCell>
                <TableCell align="center">Mail</TableCell>
                <TableCell align="center">Phones</TableCell>
                <TableCell align="center"># Images</TableCell>
                <TableCell align="center"># Gal Entry</TableCell>
                <TableCell align="center">Last Entry</TableCell>
                <TableCell align="center">Gallery</TableCell>                
              </TableRow>
            </TableHead>
            <TableBody>
              {guestrows.map((row) => (
                <TableRow key={row.dropid} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                  <TableCell align="center">
                    <Photo url={`${guestimgprefix}${row.guest_image}`}/>
                  </TableCell>
                  <TableCell align="center" component="th" scope="row"> {row.guest_id}</TableCell>
                  
                  <TableCell align="center">{row.mail}</TableCell>
                  <TableCell align="center">{row.phone}</TableCell>
                  <TableCell align="center">{row.images.length}</TableCell>
                  <TableCell align="center">{row.galentry.length }</TableCell>
                  <TableCell align="center">                    
                        {
                          row.lastentrysec < 60 ? Math.round(row.lastentrysec) + 's' :
                          row.lastentrysec < 3600 ? Math.round(row.lastentrysec / 60) + 'm':
                          row.lastentrysec < (24*3600) ? Math.round(row.lastentrysec / 3600) + 'h' :
                          Math.round(row.lastentrysec / (24*3600)) + 'd'
                        }                                      
                  </TableCell>
                  <TableCell align="center"><a href={`https://gallery.picit.me/${row.guest_id}`}> Gallery </a></TableCell>                                    
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <div>
        
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2" style={{backgroundColor: "black", color: "white"}}>
              Files uploded from the client on drop
            </Typography>            
            <TableContainer component={Paper}>
            <Table sx={{ 
                      minWidth: 650,
                      backgroundColor: "black", 
                      "& th": { fontSize: "1.0rem", color: "rgba(255, 255, 255)" },
                      "& td": { fontSize: "1.0rem", color: "rgba(255, 255, 255)" }}} 
                      aria-label="simple table" >
                <TableHead>
                  <TableRow>
                    <TableCell>Source File</TableCell>
                    <TableCell>UploadedFile</TableCell>
                    <TableCell>UploadResult</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  { clientfiles.map((row) => (
                    <TableRow key={row.SourceFile} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                      <TableCell component="th" scope="row"> {row.SourceFile} </TableCell>
                      <TableCell>{row.UploadedFile}</TableCell>
                      <TableCell>{row.UploadResult}</TableCell>
                    </TableRow>
                  ))} 
                 
                </TableBody>
              </Table>
            </TableContainer>
            
          </Box>
        </Modal>
      </div>
    </div>
  );
}
