import React from 'react'
import { ImageViewer } from "react-image-viewer-dv";
import './Photo.css';
export default function Photo({ url }) {

    return (
        <div class="item">
            <ImageViewer>
              <img src={url} width="50" height="50" alt="Event pic"/> 
            </ImageViewer>
        </div>
    )
}
